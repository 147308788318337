import React, { useState } from "react";
import { RxCrossCircled } from "react-icons/rx";
import Modal from "react-bootstrap/Modal";
import Genie from "../../assets/accounts-section/Genie.png";
import Image from "react-bootstrap/Image";
import { updateOrInsertBookTranslationChecked } from "../../Helpers/statsApiService";
import axios from "axios";

const config = require("../../Helpers/config.json");

const WordCheckModal = (props) => {
  const {
    dictionaryResultText,
    wordCheckModal,
    searchInput,
    setWordCheckModal,
    bookData,
    selectedText,
    userLanguage,
  } = props;
  const [selectedButton, setSelectedButton] = useState("dictionary");
  const [transaltionResultText, setTransaltionResultText] = useState("");
  const [isLoading, setIsLoading] = useState(false);


  const languageCodeMap = {
    english: "en",
    french: "fr",
    chinese: "zh-CN",
    germany: "de",
    hindi: "hi",
    urdu: "ur",
  };

  const handleTranslateButtonCLick = (languageCode) => {
    setIsLoading(true);
    setTransaltionResultText("...");
    axios
      .post(`${config["baseUrl3"]}/translate`, {
        text: searchInput,
        dest: languageCode,
      })
      .then(function (response) {
        setTransaltionResultText(response.data.translated_text);
        setIsLoading(false);
        updateOrInsertBookTranslationChecked(
          localStorage.getItem("id") || "",
          localStorage.getItem("profileId"),
          // localStorage.profileId || "",
          props?.bookData.id,
          1,
          languageCode,
          searchInput,
          response.data.translated_text
        );
      })
      .catch(function (error) {
        console.log(error);
        setTransaltionResultText("No translation available");
        setIsLoading(false);
      });
  };

  return (
    <div>
      <Modal
        className="parent-modal-dashboard"
        show={wordCheckModal}
        size="sm"
        onHide={() => setWordCheckModal(false)}
      >
        <Modal.Body className="word-check-modal-body">
          <div className="modal-body-main-container">
            <div
              className="cancel-btn-wrap"
              onClick={() => setWordCheckModal(false)}
            >
              <RxCrossCircled className="cancel-btn" />
            </div>
            <div className=" word-check-genie-container">
              <div className="animated-image word-check-genie-wrap">
                <Image
                  src={Genie}
                  fluid
                  className="blurred-shadow word-check-genie"
                />
              </div>
            </div>

            <div className="meaning-language-btn-wrap">
              <div className="meaning-btn-wrap">
                <button
                  className={`meaning-btn ${
                    selectedButton === "dictionary" && "font-weight-bold"
                  }`}
                  onClick={() => {
                    setSelectedButton("dictionary");
                    setTransaltionResultText("");
                  }}
                >
                  Dictionary
                </button>
              </div>
              <div className="language-btn-wrap">
                <button
                  className={`language-btn ${
                    selectedButton === "language" && "font-weight-bold"
                  }`}
                  onClick={() => {
                    setSelectedButton("language");
                    setTransaltionResultText("");
                  }}
                >
                  Language
                </button>
              </div>
            </div>
            {selectedButton === "language" && !transaltionResultText && (
              <>
                {userLanguage === "english" ? (
                  <div>
                    <div className="select-language-text-wrap">
                      <p className="select-language-text">
                        Select language to get best translation for you{" "}
                      </p>
                    </div>
                    <div className="languages-btn-container">
                      <div>
                        <button
                          className="all-languages-btn"
                          onClick={() => {
                            handleTranslateButtonCLick(
                              languageCodeMap?.english
                            );
                          }}
                        >
                          English
                        </button>
                        <button
                          className="all-languages-btn"
                          onClick={() => {
                            handleTranslateButtonCLick(
                              languageCodeMap?.germany
                            );
                          }}
                        >
                          German
                        </button>
                        <button
                          className="all-languages-btn"
                          onClick={() => {
                            handleTranslateButtonCLick(languageCodeMap?.urdu);
                          }}
                        >
                          Urdu
                        </button>
                      </div>
                      <div>
                        <button
                          className="all-languages-btn"
                          onClick={() => {
                            handleTranslateButtonCLick(
                              languageCodeMap?.chinese
                            );
                          }}
                        >
                          Chineese
                        </button>
                        <button
                          className="all-languages-btn"
                          onClick={() => {
                            handleTranslateButtonCLick(languageCodeMap?.french);
                          }}
                        >
                          French
                        </button>
                        <button
                          className="all-languages-btn"
                          onClick={() => {
                            handleTranslateButtonCLick(languageCodeMap?.hindi);
                          }}
                        >
                          Hindi
                        </button>
                      </div>
                    </div>
                  </div>
                ) : (
                  <span
                    style={{
                      fontWeight: "600",
                      color: "black",
                      marginTop: "50px",
                    }}
                  >
                    Feature is comming soon!
                  </span>
                )}
              </>
            )}
            {selectedButton === "dictionary" && (
              <>
                {userLanguage === "english" ? (
                  <p className="dictionary-text">
                    <span
                      style={{
                        fontWeight: "900",
                        textTransform: "capitalize",
                      }}
                    >
                      {searchInput ? <q>{searchInput}</q> : ""}
                    </span>
                    <br />
                    {isLoading ? (
                      <div className="loader-wrap">
                        <div className="loader translation" />
                      </div>
                    ) : (
                      <span
                        style={{
                          fontWeight: "600",
                        }}
                      >
                        {dictionaryResultText}
                      </span>
                    )}
                  </p>
                ) : (
                  <span
                    style={{
                      fontWeight: "600",
                      color: "black",
                      marginTop: "50px",
                    }}
                  >
                    Feature is comming soon!
                  </span>
                )}
              </>
            )}
            {selectedButton === "language" && transaltionResultText && (
              <>
                <p className="dictionary-text">
                  <span
                    style={{
                      fontWeight: "900",
                      textTransform: "capitalize",
                    }}
                  >
                    {searchInput ? <q>{searchInput}</q> : ""}
                  </span>
                  <br />
                  {isLoading ? (
                    <div className="loader-wrap">
                      <div className="loader translation" />
                    </div>
                  ) : (
                    transaltionResultText
                  )}
                </p>
              </>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
export default WordCheckModal;
