import React, { useState, useEffect, useRef, useCallback } from "react";
import axios from "axios";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import config from "../../Helpers/config.json";
import {
  updateOrInsertBookStats,
  updateOrInsertwordLookup,
  updateOrInsertBookTranslationChecked,
} from "../../Helpers/statsApiService";
import { toast } from "react-toastify";
import "react-resizable/css/styles.css";
import "../Book/book.css";
import "./style.css";
import "./style.css";
import DashboardNavbar from "../DashboardNavbar/DashbaordNavbar";
import donut_dark from "../../assets/dashboard/donut.png";
import donut_dark_bottom from "../../assets/dashboard/Donut-light-big.png";
import { ImCross } from "react-icons/im";
import { FaHeadphones } from "react-icons/fa6";
import { BsFillPlayFill } from "react-icons/bs";
import { MdReplay } from "react-icons/md";
import { AiOutlinePause } from "react-icons/ai";
import { FaMusic } from "react-icons/fa";
import { NavLink } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import CongratulationModal from "./congratulationModal/congratulation";
import PasswordConfirmation from "../PasswordConfirmation/PasswordConfirmation";

const BookDetails = () => {
  const location = useLocation();
  const bookData = location.state?.SelectedBook || null;
  const queryParams = new URLSearchParams(location.search);
  const userLanguage = queryParams.get("lang") || "english";
  const [allLanguagesSupport, setAllLanguagesSupport] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [allStory, setAllStory] = useState([]);
  const [showStory, setShowStory] = useState({});
  const [currentPage, setCurrentPage] = useState(0);
  const [readData, setReadData] = useState("");
  const [endingIndex, setEndingIndex] = useState(0);
  const [highlightedText, setHighlightedText] = useState("");
  const [translationToolTip, setTranslationToolTip] = useState(false);
  const [category, setCategory] = useState("");
  const [isToolTipLoading, setIsToolTipLoading] = useState(false);
  const [apiResponse, setAPIResponse] = useState({});
  const [translatedText, setTranslatedText] = useState({});
  const [selectCounrty, setSelectedCountry] = useState("");
  const [highlightPosition, setHighlightPosition] = useState({
    top: 0,
    left: 0,
  });
  const [selectedText, setSelectedText] = useState("");
  const [isDictionaryOpen, setDictionaryOpen] = useState(false);
  const [isLanguageOpen, setLanguageOpen] = useState(false);
  const [translationOpen, setTranslationOpen] = useState(false);
  const [audioUrl, setAudioUrl] = useState("");
  const [audioLoading, setAudioLoading] = useState(false);
  const [timeSpent, setTimeSpent] = useState(0);
  const [isTimeSpent, setIsTimeSpent] = useState(0);
  const [selectedLanguage, setSelectedLanguage] = useState("French");
  const [englishAudioUrl, setenglishAudioUrl] = useState("");
  const [audioSource, setAudioSource] = useState(undefined);
  const [showCongrateModal, setShowCongrateModal] = useState(false);
  const [valueCheck, setValueCheck] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  const utteranceRef = useRef(null);
  const substringsRef = useRef([]);
  const audioPlayerRef = useRef();
  const mainAudioRef = useRef(null);
  const mainAudioResRef = useRef(null);

  const backgroundMusicRef = useRef(null);
  const backgroundMusicEngRef = useRef(null);

  const [isPlaying, setIsPlaying] = useState(false);
  const [backgroundMusicVolume, setBackgroundMusicVolume] = useState(0.2);

  const backgroundMusicUrl =
    "https://tts.azure.fictiongenie.com/audio/180126-ambient-morning-chill-with-birds-21348_FinalUrdu.mp3";
  const backgroundMusicEngUrl =
    "https://tts.azure.fictiongenie.com/audio/in-the-jungle-electronic-adventure-music-7659_Final.mp3";
  // if (!mainAudioRef?.current?.paused) {
  //   // mainAudioRef?.current?.pause();
  //   backgroundMusicRef?.current?.play();
  // } else if (!mainAudioResRef?.current?.paused) {
  //   backgroundMusicRef?.current?.play();
  // } else {
  //   // mainAudioRef?.current?.play();
  //   backgroundMusicRef?.current?.pause();
  // }



  const handleVolumeChange = (event) => {
    const volume = parseFloat(event.target.value);
    setBackgroundMusicVolume(volume);
    backgroundMusicRef.current.volume = volume;
  };

  const handleVolumeEngChange = (event) => {
    const volume = parseFloat(event.target.value);
    setBackgroundMusicVolume(volume);
    backgroundMusicEngRef.current.volume = volume;
  };
  const [buttonState, setButtonState] = useState({
    listen: true,
    pause: false,
    resume: false,
  });

  useEffect(() => {
    if ("speechSynthesis" in window) window.speechSynthesis.cancel();
    let allLang = ["english", "french", "chinese", "germany", "hindi", "urdu"];
    setAllLanguagesSupport(
      allLang.filter((lang) => lang !== userLanguage) || []
    );
    if (userLanguage === "english") {
      getBookData();
    } else {
      axios
        .get(
          `${config["baseUrl"]}/bookPageTranslation/${bookData?.id}/${userLanguage}`
        )
        .then(({ data: res }) => {
          if (res.result === "success") {
            let tempStory = [];
            for (let story of res?.data) {
              const parser = new DOMParser();
              const doc = parser.parseFromString(
                story?.translation,
                "text/html"
              );
              const paragraphs = Array.from(doc.querySelectorAll("p")).map(
                (p) => p.textContent
              );
              const newPara = paragraphs.join("\n\n");
              let splittedText = newPara?.split("@");
              let modifiedText = splittedText[splittedText.length - 1];
              tempStory.push({
                text: modifiedText,
                title: bookData?.title,
                ...story,
              });
            }
            let copystory = [...tempStory];
            setAllStory(tempStory);
            setShowStory(tempStory[0] || {});
            setReadData(
              copystory
                ?.splice(0, 1)
                ?.map((item) => item?.text)
                ?.join(" ") || ""
            );
            setIsLoading(false);
          }
        })
        .catch((err) => {
          setIsLoading(false);
        });
    }
  }, [userLanguage]);

  const getBookData = async () => {
    axios
      .get(`${config["baseUrl"]}/getbookscontent/${bookData?.id}`)
      .then(({ data: res }) => {
        if (res.message === "fetched") {
          let tempStory = [];

          for (let story of res?.data) {
            const parser = new DOMParser();
            const doc = parser.parseFromString(story?.content, "text/html");
            const paragraphs = Array.from(doc.querySelectorAll("p")).map(
              (p) => p.textContent
            );
            const newPara = paragraphs.join("\n\n");
            const atIndex = newPara.indexOf("&");
            const newParaData =
              atIndex !== -1 ? newPara.substring(atIndex + 1) : null;
            let splittedText = newParaData?.split("@");
            let modifiedText = splittedText[splittedText.length - 1];
            const chapterIndex = newPara.toLowerCase().indexOf("chapter:");
            const chapterText =
              atIndex !== -1
                ? newPara.substring(chapterIndex, atIndex)
                : newPara.substring(chapterIndex);
            const lines = chapterText.split("\n");
            const chapterTitles = lines
              .map((line) => {
                const chapterIndex = line.indexOf("Chapter");
                return chapterIndex !== -1
                  ? line.substring(chapterIndex)
                  : null;
              })
              .filter((chapterTitle) => chapterTitle !== null);

            tempStory.push({
              text: modifiedText,
              chapter: chapterTitles?.toString(),
              title: bookData?.title,
              ...story,
            });
          }
          let copystory = [...tempStory];
          setAllStory(tempStory);

          setShowStory(tempStory[0] || {});
          setReadData(
            copystory
              ?.splice(0, 1)
              ?.map((item) => item?.text)
              ?.join(" ") || ""
          );
          setIsLoading(false);
        }
      });
  };

  useEffect(() => {
    let startIndex = showStory?.text
      ?.split(".")
      .join("")
      .indexOf(highlightedText);
    let endIndex = startIndex + highlightedText?.length - 1;
    setEndingIndex(endIndex);
  }, [highlightedText, showStory]);

  useEffect(() => {
    if (currentPage === 0 || currentPage === 4) {
      if (timeSpent === 30) {
        setValueCheck(true);
        updateOrInsertBookStats(
          localStorage.getItem("id"),
          localStorage.profileId,
          bookData.id,
          currentPage + 2
        );
      }
    }
  }, [timeSpent]);
  // useEffect(() => {
  //   if (currentPage === 4) {
  //     if (timeSpent === 30) {
  //       setShowCongrateModal(true);
  //     }
  //   }
  // }, [timeSpent]);
  const congratulationModalOpen = () => {
    if (valueCheck === true) {
      setShowCongrateModal(true);
    }else{
      navigate("/dashboard");
    }
  };
  console.log("congratulationModalOpen" , showCongrateModal)

  //console.log("currentPage", currentPage)
  const nextPage = () => {
    setAudioUrl(null);
    againPlay();

    if (currentPage !== allStory.length - 1) {
      let temp = [...allStory];
      setShowStory(temp[currentPage + 1] || {});
      setReadData(
        temp
          ?.splice(currentPage + 1, 1)
          ?.map((item) => item?.text)
          ?.join(" ") || ""
      );
      if (timeSpent >= 30) {
        updateOrInsertBookStats(
          localStorage.getItem("id"),
          localStorage.profileId,
          bookData.id,
          currentPage + 2
        );
      }
      setCurrentPage((prev) => prev + 1);
      const timer = isTimeSpent + 1;
      setIsTimeSpent(timer);
    }
    return;
  };

  const prevPage = () => {
    setAudioUrl(null);
    againPlay();
    if (currentPage !== 0) {
      let temp = [...allStory];
      setShowStory(temp[currentPage - 1] || {});
      setReadData(
        temp
          ?.splice(currentPage - 1, 1)
          ?.map((item) => item?.text)
          ?.join(" ") || ""
      );
      setCurrentPage((prev) => prev - 1);
    }
    return;
  };

  const playBook = async () => {
    setAudioLoading(true);
    const text = readData;
    const fileName =
      "" +
      userLanguage +
      "_" +
      bookData?.id +
      "_" +
      showStory?.title +
      "_" +
      currentPage +
      "_6";
    const language = "ur-PK";
    const voice = "ur-PK-UzmaNeural";

    try {
      const response = await fetch(
        "https://tts.azure.fictiongenie.com/generate-speech",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ text, fileName, language, voice }),
        }
      );

      if (response.ok) {
        const data = await response.json();
        console.log(data);

        if (data.path) {
          setAudioLoading(false);
          setAudioUrl(
            `https://tts.azure.fictiongenie.com/audio/${fileName}.mp3`
          );
        }
      } else {
        console.error("Error:", response.status);
        setAudioLoading(false);
      }
    } catch (error) {
      console.error("Error:", error);
      setAudioLoading(false);
    }
  };
  const playBookInEnglish = async () => {
    setAudioLoading(true);
    const text = readData;
    const fileName =
      "" +
      userLanguage +
      "_" +
      bookData?.id +
      "_" +
      bookData?.title +
      "_" +
      currentPage +
      "_6";
    const language = "en-US";
    const voice = "Ivy";

    try {
      const response = await fetch(
        "https://tts.amazon.fictiongenie.com/generate-speech",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ text, fileName, language, voice }),
        }
      );

      if (response.ok) {
        const data = await response.json();
        console.log(data);

        if (data.path) {
          setAudioLoading(false);
          setenglishAudioUrl(
            `https://tts.amazon.fictiongenie.com/audio/${fileName}.mp3`
          );
          fetchSpeechMarks(
            `https://tts.amazon.fictiongenie.com/audio/${fileName}-marks.json`
          );
        }
      } else {
        console.error("Error:", response.status);
        setAudioLoading(false);
      }
    } catch (error) {
      console.error("Error:", error);
      setAudioLoading(false);
    }
  };

  function fetchSpeechMarks(url) {
    // fetch(url)
    // .then(response => response.text())
    // .then(text => {
    // Split the text by newlines and parse each line as JSON
    const textContent = [
      {
        time: 0,
        type: "sentence",
        start: 0,
        end: 51,
        value: "In a big, green jungle, many animals lived happily.",
      },
      {
        time: 4180,
        type: "sentence",
        start: 52,
        end: 105,
        value: "The kindest of them all was Ellie, a gentle elephant.",
      },
      {
        time: 7697,
        type: "sentence",
        start: 106,
        end: 145,
        value: "Ellie loved to walk through the jungle.",
      },
      {
        time: 10052,
        type: "sentence",
        start: 146,
        end: 206,
        value: "She listened to the birds sing and watched the monkeys play.",
      },
      {
        time: 13695,
        type: "sentence",
        start: 207,
        end: 231,
        value: "The jungle was peaceful.",
      },
      {
        time: 15475,
        type: "sentence",
        start: 232,
        end: 279,
        value: "The trees were tall, and the rivers were clear.",
      },
      {
        time: 18517,
        type: "sentence",
        start: 280,
        end: 329,
        value: "One day, Ellie met a small bird with a hurt wing.",
      },
      {
        time: 22172,
        type: "sentence",
        start: 331,
        end: 381,
        value: 'Don\'t worry," Ellie said softly, "I will help you.',
      },
      {
        time: 26052,
        type: "sentence",
        start: 383,
        end: 451,
        value:
          "She carefully lifted the bird and found a safe place for it to rest.",
      },
      {
        time: 30232,
        type: "sentence",
        start: 452,
        end: 487,
        value: "Other animals saw Ellie's kindness.",
      },
      {
        time: 32812,
        type: "sentence",
        start: 488,
        end: 515,
        value: "They smiled and felt happy.",
      },
      {
        time: 34792,
        type: "sentence",
        start: 516,
        end: 559,
        value: "Ellie walked on, greeting everyone she met.",
      },
      {
        time: 38010,
        type: "sentence",
        start: 560,
        end: 628,
        value:
          "She shared food with the smaller animals and helped them find water.",
      },
      {
        time: 41927,
        type: "sentence",
        start: 629,
        end: 687,
        value: "The jungle was a happy place because of Ellie's big heart.",
      },
      {
        time: 45470,
        type: "sentence",
        start: 688,
        end: 707,
        value: "Everyone loved her.",
      },
      {
        time: 47012,
        type: "sentence",
        start: 708,
        end: 746,
        value: "But not far away, Hyena watched Ellie.",
      },
      {
        time: 50180,
        type: "sentence",
        start: 747,
        end: 779,
        value: "His eyes narrowed with jealousy.",
      },
      {
        time: 52797,
        type: "sentence",
        start: 780,
        end: 820,
        value: "He didn't like how everyone loved Ellie.",
      },
      {
        time: 55477,
        type: "sentence",
        start: 821,
        end: 866,
        value: 'He whispered to himself, "I will change this.',
      },
      {
        time: 58632,
        type: "sentence",
        start: 868,
        end: 922,
        value: "Hyena's plan would soon shake the peace of the jungle.",
      },
    ];

    const text = textContent.map((data) => data);

    const lines = JSON.stringify(text).trim().split("\n");
    const speechMarks = lines.map((line) => JSON.parse(line));
    const actualText = readData;
    setupTextDisplay(actualText, speechMarks);
    // })
    // .catch(error => console.error('Error fetching speech marks:', error));
  }

  const handleTimeUpdate = () => {
    // Get the current source of the audio element
    const currentSource = audioPlayerRef.current;
    setAudioSource(currentSource);
  };

  useEffect(() => {
    console.log("raudio ref value in useEffect: ", audioPlayerRef.current);
    console.log("audio source value in effect", audioSource);
    setAudioSource(audioPlayerRef.current);
  }, [audioPlayerRef, audioSource]);

  const setupTextDisplay = (text, speechMarks) => {
    const textDisplay = text;

    const audioPlayer = audioSource;
    let currentMarkIndex = 0;

    console.log("ref value: ", audioPlayerRef.current);
    console.log("audioPlayer", audioPlayer);
    const currentTime = 68306.70000000298 * 1000; // Convert to milliseconds

    while (
      currentMarkIndex < speechMarks.length &&
      speechMarks[currentMarkIndex].time <= currentTime
    ) {
      currentMarkIndex++;
    }

    highlightWord(currentMarkIndex - 1, speechMarks, textDisplay, text);
  };

  function highlightWord(index, speechMarks, textDisplay, originalText) {
    if (index < 0 || index >= speechMarks.length) return;

    const mark = speechMarks[index];
    console.log("mark", mark);
    let highlightedText = "";
    highlightedText += originalText.substring(0, mark.start);
    highlightedText += `<span style="background-color: yellow;">${originalText.substring(
      mark.start,
      mark.end
    )}</span>`;
    highlightedText += originalText.substring(mark.end);

    textDisplay.innerHTML = highlightedText;
  }

  const onSpeakText = (index, data, inititialCase = false) => {
    console.log("data", data);

    if (inititialCase) {
      return setHighlightedText(data);
    }
    setHighlightedText((e) => (e ? `${e}.${data}` : `${data}`));
  };

  const generalCall = () => {
    if (buttonState.listen) {
      speakText();
      setButtonState({ ...buttonState, listen: false, pause: true });
    } else if (buttonState.pause) {
      pauseSpeech();
      setButtonState({ ...buttonState, pause: false, resume: true });
    } else if (buttonState.resume) {
      resumeSpeech();
      setButtonState({ ...buttonState, pause: true, resume: false });
    }
  };

  const againPlay = () => {
    window.speechSynthesis.cancel();
    onSpeakText(0, "", true);
    setReadData(readData);
    setButtonState({
      ...buttonState,
      listen: true,
      pause: false,
      resume: false,
    });
  };

  const speakText = (data = "") => {
    if ("speechSynthesis" in window) {
      if (userLanguage === "hindi") {
        substringsRef.current = readData?.split(",");
      } else if (userLanguage === "chinese") {
        substringsRef.current = readData
          ?.split(/[。！？]/)
          ?.filter((sentence) => sentence.trim() !== "");
      } else if (userLanguage === "urdu") {
        substringsRef.current = readData
          ?.split(/۔|\?|!/)
          ?.filter((sentence) => sentence.trim() !== "");
      } else {
        substringsRef.current = readData
          ?.split(/[.!?]/)
          ?.filter((sentence) => sentence.trim() !== "");
      }
      playNext(0);
    } else {
      // console.log('Text-to-speech not supported in this browser.');
    }
  };

  const playNext = (index) => {
    backgroundMusicEngRef?.current?.play();
    if (index >= substringsRef.current.length) {
      setHighlightedText("");
      setButtonState({ ...buttonState, pause: false, listen: true });
      return;
    }

    const substring = substringsRef.current[index];
    utteranceRef.current = new SpeechSynthesisUtterance(substring);

    utteranceRef.current.onstart = () => {
      onSpeakText(index + 1, substring);
    };

    utteranceRef.current.onend = () => {
      playNext(index + 1);
    };

    var voices = window.speechSynthesis.getVoices();
    var customVoice;
    if (userLanguage === "french") {
      customVoice = voices.find((voice) => voice.name === "Google français");
    } else if (userLanguage === "hindi") {
      customVoice = voices.find((voice) => voice.name === "Google हिन्दी");
    } else if (userLanguage === "chinese") {
      customVoice = voices.find(
        (voice) => voice.name === "Google 普通话（中国大陆）"
      );
    } else if (userLanguage === "german") {
      customVoice = voices.find((voice) => voice.name === "Google Deutsch");
    } else if (userLanguage === "urdu") {
      customVoice = voices.find((voice) => voice.name === "Google हिन्दी");
    } else {
      customVoice = voices.find(
        (voice) => voice.name === "Google UK English Female"
      );
    }

    utteranceRef.current.voice = customVoice; // set voicen

    utteranceRef.current.rate = 1.0; // set pitch (0-2) range
    window.speechSynthesis.speak(utteranceRef.current);
  };

  const pauseSpeech = () => {
    backgroundMusicEngRef?.current?.pause();
    if (utteranceRef.current) {
      window.speechSynthesis.pause();
    }
  };

  const resumeSpeech = () => {
    backgroundMusicEngRef?.current?.play();
    if (utteranceRef.current) {
      // Ensure the utteranceRef.current is not null or undefined
      if (window.speechSynthesis.speaking && !window.speechSynthesis.paused) {
        window.speechSynthesis.resume();
      } else {
        againPlay();
        playNext(0);
      }
    }
  };

  const handleTextSelection = () => {
    const selection = window.getSelection();
    const text = selection?.toString().trim();

    if (text) {
      const range = selection.getRangeAt(0);
      const rect = range.getBoundingClientRect();
      const position = {
        top: rect.top - 150,
        left: rect.left + window.scrollX + rect.width - 70,
      };

      setHighlightPosition(position);
      setSelectedText(text);

      if (isDictionaryOpen) {
        handleCloseDictionary();
      }

      if (isLanguageOpen) {
        handleCloseLanguage();
        handleDoneLanguageClose();
      }
    }
  };

  const searchDetail = (type) => {
    setCategory(type);
    axios
      .get(`https://api.dictionaryapi.dev/api/v2/entries/en/${selectedText}`)
      .then(function (response) {
        setAPIResponse(response?.data[0]?.meanings?.[0] || {});
        updateOrInsertwordLookup(
          localStorage.getItem("id"),
          localStorage.profileId,
          bookData.id,
          selectedText,
          1,
          response?.data[0]?.meanings[0].definitions[0].definition
        );
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const languageCodeMap = {
    english: "en",
    french: "fr",
    chinese: "zh-CN",
    germany: "de",
    hindi: "hi",
    urdu: "ur",
  };

  const countrySelect = (countryName) => {
    if (!selectedText) {
      toast.warn("Please select text", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }

    const languageCode = languageCodeMap[countryName];
    if (!languageCode) {
      return;
    }

    setIsToolTipLoading(true);
    setSelectedCountry(countryName);

    axios
      .post(`${config["baseUrl3"]}/translate`, {
        text: selectedText,
        dest: languageCode,
      })
      .then(function (response) {
        setTranslatedText(response.data.translated_text);
        setTranslationToolTip(true);
        setIsToolTipLoading(false);
        updateOrInsertBookTranslationChecked(
          localStorage.getItem("id"),
          localStorage.profileId,
          bookData.id,
          1,
          languageCode,
          selectedText,
          response.data.translated_text
        );
      })
      .catch(function (error) {
        console.log(error);
        setIsToolTipLoading(false);
      });
  };

  const handleOpenDictionary = () => {
    setDictionaryOpen(true);
    searchDetail();
  };

  const handleCloseDictionary = () => {
    setDictionaryOpen(false);
    setHighlightPosition({ top: 0, left: 0 });
  };

  const handleOpenLanguage = () => {
    setLanguageOpen(true);
  };

  const handleCloseLanguage = () => {
    setLanguageOpen(false);
    setHighlightPosition({ top: 0, left: 0 });
  };

  const handleSelectLanguage = (language) => {
    setSelectedLanguage(language);
  };

  const handleDoneLanguage = (lang) => {
    setTranslationOpen(true);
    countrySelect(lang);
  };

  const handleDoneLanguageClose = () => {
    setTranslationOpen(false);
  };

  useEffect(() => {
    const startTime = new Date().getTime();
    const interval = setInterval(() => {
      const currentTime = new Date().getTime();
      const elapsedTime = Math.floor((currentTime - startTime) / 1000);
      setTimeSpent(elapsedTime);
    }, 1000);
    return () => clearInterval(interval);
  }, [isTimeSpent]);

  // console.log("highlightedText", highlightedText)

  // const toggleElementVisibility = () => {
  //   setVolumeShow(!volumeShow);
  // };

  return (
    <div className="new-dashboard-wrapper">
      {isLoading && (
        <div className="loader-overlay">
          <div className="loader" />
        </div>
      )}
      <div className="dashbaord-donut-container">
        <img src={donut_dark} className="dashbaord-donut" />
      </div>
      <DashboardNavbar selectedText={selectedText} bookData={bookData}  userLanguage={userLanguage} setShowModal={setShowModal}/>
      <img src={donut_dark_bottom} className="dashbaord-donut-bottom" />
      <div className="content-wrrapers">
        <div className="sub-content-wrrapers" id="sub-content-wraps">
          <div
            className="book-main-wraps book-container"
            style={{
              background: `url(${new URL(
                `${config["baseUrl"]}${showStory?.image}`
              )})`,
            }}
          >
            <div className="left">
              <div className="book-detail-wrap-sub">
                <div className="left-top">
                  <div className="book-header-main">
                    <div className="book-name-writer-responsive">
                      <h1 className="head-story"> {bookData?.title}</h1>
                      <div className="story-writter-container">
                        <p className="story-wriiter">{showStory?.chapter}</p>
                      </div>
                      {userLanguage === "urdu" ? (
                        <div className="read-func-container">
                          {audioLoading ? (
                            <div
                              className="resume-pause-btn"
                              style={{
                                backgroundColor: "transparent",
                                boxShadow: "unset",
                              }}
                              onClick={playBook}
                            >
                              <p style={{ color: "#FF6B0A", fontSize: "20PX" }}>
                                Audio Loading...
                              </p>
                            </div>
                          ) : !audioUrl ? (
                            <div
                              className="resume-pause-btn"
                              onClick={playBook}
                            >
                              <FaHeadphones size={30} color="white" />
                            </div>
                          ) : (
                            <>
                              <div className="audio">
                                <audio controls ref={mainAudioRef}>
                                  <source src={audioUrl} type="audio/mp3" />
                                </audio>
                              </div>
                              {/* <audio
                                ref={backgroundMusicRef}
                                loop
                                volume={backgroundMusicVolume}
                              >
                                <source
                                  src={backgroundMusicUrl}
                                  type="audio/mp3"
                                />
                              </audio>
                              <div className="volume-main-wrap">
                                <div className="icon-volume-wrap">
                                  <div className="icon-wrap">
                                    <FaMusic className="volume-icon" />
                                  </div>
                                  <div className="music-volume-container">
                                    <div
                                      className="music-vloume"
                                      style={{ position: "relative" }}
                                    >
                                      <input
                                        type="range"
                                        id="volume"
                                        min="0"
                                        max="1"
                                        step="0.1"
                                        value={backgroundMusicVolume}
                                        onChange={handleVolumeChange}
                                      />
                                      <label
                                        htmlFor="volume"
                                        style={{
                                          position: "absolute",
                                          top: 0,
                                          left: 0,
                                          display: "none",
                                          background: "#333",
                                          color: "#fff",
                                          padding: "5px",
                                          borderRadius: "3px",
                                          cursor: "default",
                                        }}
                                      >
                                        Background Music Volume:
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div> */}
                            </>
                          )}
                        </div>
                      ) : (
                        // :
                        // userLanguage === "english" ?
                        // 	<div className='read-func-container'>
                        // 		{
                        // 			audioLoading ?
                        // 				<div className='resume-pause-btn' style={{ backgroundColor: "transparent", boxShadow: "unset" }} onClick={playBookInEnglish}>
                        // 					<p style={{ color: "#FF6B0A", fontSize: "20PX" }}>Audio Loading...</p>
                        // 				</div>
                        // 				:
                        // 				!englishAudioUrl ?
                        // 					<div className='resume-pause-btn' onClick={playBookInEnglish}>
                        // 						<FaHeadphones size={30} color='white' />
                        // 					</div>
                        // 					:
                        // 					<div className='audio'>
                        // 						<audio controls ref={audioPlayerRef} onTimeUpdate={handleTimeUpdate}>
                        // 							<source src={englishAudioUrl} type="audio/mp3" />
                        // 							Your browser does not support the audio tag.
                        // 						</audio>
                        // 					</div>
                        // 		}
                        // 	</div>
                        <div className="read-func-container">
                          <div className="replay-btn" onClick={againPlay}>
                            <MdReplay size={30} color="white" />
                          </div>
                          <div
                            className="resume-pause-btn"
                            onClick={generalCall}
                          >
                            {buttonState.listen || buttonState.resume ? (
                              <BsFillPlayFill size={30} color="white" />
                            ) : (
                              <AiOutlinePause size={30} color="white" />
                            )}
                          </div>

                          {/* <audio
                            ref={backgroundMusicEngRef}
                            loop
                            volume={backgroundMusicVolume}
                          >
                            <source
                              src={backgroundMusicEngUrl}
                              type="audio/mp3"
                            />
                          </audio>
                          <div className="volume-main-wrap">
                            <div className="icon-volume-wrap">
                              <div className="icon-wrap">
                                <FaMusic className="volume-icon" />
                              </div>
                              <div className="music-volume-container">
                                <div
                                  className="music-vloume"
                                  style={{ position: "relative" }}
                                >
                                  <input
                                    type="range"
                                    id="volume"
                                    min="0"
                                    max="1"
                                    step="0.1"
                                    value={backgroundMusicVolume}
                                    onChange={handleVolumeEngChange}
                                  />
                                  <label
                                    htmlFor="volume"
                                    style={{
                                      position: "absolute",
                                      top: 0,
                                      left: 0,
                                      display: "none",
                                      background: "#333",
                                      color: "#fff",
                                      padding: "5px",
                                      borderRadius: "3px",
                                      cursor: "default",
                                    }}
                                  >
                                    Background Music Volume:
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div> */}
                        </div>
                      )}
                    </div>
                    <div
                      className={`img-container mt-0 img-container-responsive`}
                    >
                      <div className="dashboard-img-book-cover mt-0 book-cover-responsive">
                        <img
                          className="dashboard-img dashboard-img-responsive"
                          src={`${config["baseUrl"]}${showStory?.image}`}
                          alt=""
                        />
                        <div className="book-spine"></div>
                      </div>
                      {userLanguage === "urdu" ? (
                        <div className="read-func-container read-func-container-responsive">
                          {audioLoading ? (
                            <div
                              className="resume-pause-btn"
                              style={{
                                backgroundColor: "transparent",
                                boxShadow: "unset",
                              }}
                              onClick={playBook}
                            >
                              <p style={{ color: "#FF6B0A", fontSize: "20PX" }}>
                                Audio Loading...
                              </p>
                            </div>
                          ) : !audioUrl ? (
                            <div
                              className="resume-pause-btn"
                              onClick={playBook}
                            >
                              <FaHeadphones size={30} color="white" />
                            </div>
                          ) : (
                            <div className="audio-volume-container">
                              <div className="audio">
                                <audio controls ref={mainAudioResRef}>
                                  <source src={audioUrl} type="audio/mp3" />
                                </audio>
                              </div>
                              <audio
                                ref={backgroundMusicRef}
                                loop
                                volume={backgroundMusicVolume}
                              >
                                <source
                                  src={backgroundMusicUrl}
                                  type="audio/mp3"
                                />
                              </audio>
                              <div className="volume-main-wrap">
                                <div className="icon-volume-wrap">
                                  <div className="icon-wrap">
                                    <FaMusic className="volume-icon" />
                                  </div>
                                  <div className="music-volume-container">
                                    <div
                                      className="music-vloume"
                                      style={{ position: "relative" }}
                                    >
                                      <input
                                        type="range"
                                        id="volume"
                                        min="0"
                                        max="1"
                                        step="0.1"
                                        value={backgroundMusicVolume}
                                        onChange={handleVolumeChange}
                                      />
                                      <label
                                        htmlFor="volume"
                                        style={{
                                          position: "absolute",
                                          top: 0,
                                          left: 0,
                                          display: "none",
                                          background: "#333",
                                          color: "#fff",
                                          padding: "5px",
                                          borderRadius: "3px",
                                          cursor: "default",
                                        }}
                                      >
                                        Background Music Volume:
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      ) : (
                        // :
                        // userLanguage === "english" ?
                        // 	<div className='read-func-container read-func-container-responsive' >
                        // 		{
                        // 			audioLoading ?
                        // 				<div className='resume-pause-btn' style={{ backgroundColor: "transparent", boxShadow: "unset" }} onClick={playBookInEnglish}>
                        // 					<p style={{ color: "#FF6B0A", fontSize: "20PX" }}>Audio Loading...</p>
                        // 				</div>
                        // 				:
                        // 				!audioUrl ?
                        // 					<div className='resume-pause-btn' onClick={playBookInEnglish}>
                        // 						<FaHeadphones size={30} color='white' />
                        // 					</div>
                        // 					:
                        // 					<div>
                        // 						<audio controls id="audioPlayer" onClick={playBookInEnglish}>
                        // 							<source src={audioUrl} type="audio/mp3" />
                        // 							Your browser does not support the audio tag.
                        // 						</audio>
                        // 					</div>
                        // 		}

                        // 	</div>
                        <div className="read-func-container read-func-container-responsive">
                          <div className="replay-btn" onClick={againPlay}>
                            <MdReplay size={30} color="white" />
                          </div>
                          <div
                            className="resume-pause-btn"
                            onClick={generalCall}
                          >
                            {buttonState.listen || buttonState.resume ? (
                              <BsFillPlayFill size={30} color="white" />
                            ) : (
                              <AiOutlinePause size={30} color="white" />
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="head-story-responsive">
                    <h1 className="head-story"> {showStory?.title}</h1>
                    <div className="story-writter-container">
                      <p className="story-wriiter">{showStory?.chapter}</p>
                    </div>
                  </div>
                  <div>
                    {allStory.length === 0 ? (
                      <div className="not-found-story">
                        <p>No content found!</p>
                      </div>
                    ) : (
                      <p
                        className={
                          userLanguage === "urdu"
                            ? "text-story text-story-urdu"
                            : "text-story"
                        }
                        onMouseUp={handleTextSelection}
                      >
                        {" "}
                        <span style={{ backgroundColor: "#ffffff30" }}>
                          {highlightedText}{" "}
                        </span>{" "}
                        {!!highlightedText.length
                          ? showStory?.text?.slice(endingIndex + 2)
                          : showStory?.text?.slice(endingIndex + 1)}
                        {highlightPosition.top !== 0 && (
                          <>
                            {/* {!(isDictionaryOpen || isLanguageOpen) && (
															<div
																className='highlight-div'
																style={{
																	top: `${highlightPosition.top}px`,
																	left: `${highlightPosition.left}px`,
																}}
															>
																<div className='dictionary-container' >
																	<p style={{ color: 'white', margin: '0px' }} onClick={handleOpenDictionary}>Dictionary </p>
																</div>
																<div className='language-container' >
																	<p style={{ color: 'white', margin: '0px' }} onClick={handleOpenLanguage}>Language</p>
																</div>

															</div>
														)} */}
                            {isDictionaryOpen && (
                              <div
                                className="click-on-dictionary-container"
                                style={{
                                  top: `${highlightPosition.top}px`,
                                  left: `${highlightPosition.left}px`,
                                }}
                              >
                                <div className="click-on-dictionary">
                                  <div className="click-on-dictionary-hd-wrap">
                                    <p>{selectedText}</p>
                                  </div>
                                  <div className="click-on-dictionary-text-wrap">
                                    <>
                                      {userLanguage === "english" ? (
                                        apiResponse?.definitions &&
                                        apiResponse?.definitions?.length ===
                                          0 ? (
                                          <p>not found</p>
                                        ) : (
                                          apiResponse?.definitions?.map(
                                            (item) => {
                                              return (
                                                <p>
                                                  {item
                                                    ? item?.definition
                                                    : "No content found!"}
                                                </p>
                                              );
                                            }
                                          )
                                        )
                                      ) : (
                                        <p>Feature is coming soon</p>
                                      )}
                                    </>
                                  </div>
                                  <div
                                    className="cross-icon"
                                    onClick={handleCloseDictionary}
                                  >
                                    <ImCross
                                      style={{
                                        color: "black",
                                        fontSize: "20px",
                                        fontWeight: "bolder",
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            )}
                            {isLanguageOpen && !translationOpen && (
                              <div
                                className="select-language-container"
                                style={{
                                  top: `${highlightPosition.top}px`,
                                  left: `${highlightPosition.left}px`,
                                }}
                              >
                                <div className="select-language">
                                  <div className="select-language-hd-wrap">
                                    <p>Select Language</p>
                                  </div>
                                  <div className="change-languages">
                                    <ul>
                                      {allLanguagesSupport?.map((lang) => {
                                        return (
                                          <li
                                            className={`all-language ${
                                              selectedLanguage === lang
                                                ? "selected-language"
                                                : ""
                                            }`}
                                            onClick={() => {
                                              handleSelectLanguage(lang);
                                            }}
                                          >
                                            {" "}
                                            {lang}
                                          </li>
                                        );
                                      })}
                                    </ul>
                                  </div>
                                  <div className="cancel-done-btn-wrap">
                                    <div
                                      className="cencel-btn-container"
                                      onClick={handleCloseLanguage}
                                    >
                                      <p>Cancel </p>
                                    </div>
                                    <div
                                      className="done-btn-container"
                                      onClick={() =>
                                        handleDoneLanguage(selectedLanguage)
                                      }
                                    >
                                      <p>Done</p>
                                    </div>
                                  </div>
                                  <div
                                    className="cross-icon"
                                    onClick={handleCloseLanguage}
                                  >
                                    <ImCross
                                      style={{
                                        color: "black",
                                        fontSize: "20px",
                                        fontWeight: "bolder",
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            )}
                            {translationOpen && (
                              <div
                                className="english-language-container"
                                style={{
                                  top: `${highlightPosition.top}px`,
                                  left: `${highlightPosition.left}px`,
                                }}
                              >
                                <div className="english-language">
                                  <div className="english-hd-wrap">
                                    <p>Translating to {selectedLanguage}</p>
                                  </div>
                                  <div className="English-language-text">
                                    <p className="m-0">
                                      <strong>Text:</strong> {selectedText}
                                    </p>
                                    <p className="m-0 translation">
                                      {isToolTipLoading ? (
                                        <Spinner
                                          className="pt-10"
                                          animation="border"
                                        />
                                      ) : (
                                        translatedText ||
                                        "Translation not found"
                                      )}
                                    </p>
                                  </div>
                                  <div
                                    className="cross-icon"
                                    onClick={handleDoneLanguageClose}
                                  >
                                    <ImCross
                                      style={{
                                        color: "black",
                                        fontSize: "20px",
                                        fontWeight: "bolder",
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            )}
                          </>
                        )}
                      </p>
                    )}
                  </div>
                </div>
                <div className="left-bottom">
                  <div className="label">
                    {currentPage + 1 > 1 && (
                      <button
                        style={{
                          fontSize: "16px",
                          cursor: "pointer",
                          color: "white",
                          backgroundColor: "#8a12ef",
                          border: "none",
                          borderRadius: "13px",
                          padding: "8px 15px",
                          margin: "5px",
                        }}
                        onClick={prevPage}
                      >
                        Previous
                      </button>
                    )}
                    <span className="range">
                      {allStory.length === 0
                        ? `${currentPage} of ${allStory.length}`
                        : `${currentPage + 1} of ${allStory.length}`}
                    </span>
                    {currentPage <= 3 && (
                      <button
                        style={{
                          fontSize: "16px",
                          cursor: "pointer",
                          color: "white",
                          backgroundColor: "#8a12ef",
                          border: "none",
                          borderRadius: "13px",
                          padding: "8px 15px",
                          margin: "5px",
                        }}
                        onClick={nextPage}
                      >
                        Next
                      </button>
                    )}
                    {currentPage === 4 && (
                      <button
                        style={{
                          fontSize: "16px",
                          cursor: "pointer",
                          color: "white",
                          backgroundColor: "#8a12ef",
                          border: "none",
                          borderRadius: "13px",
                          padding: "8px 15px",
                          margin: "5px",
                        }}
                        onClick={congratulationModalOpen}
                      >
                        Finish
                      </button>
                    )}
                  </div>
                </div>
                <CongratulationModal showCongrateModal={showCongrateModal} setShowCongrateModal={setShowCongrateModal} />
              </div>
            </div>
          </div>
          <div className="background-container"></div>
        </div>
      </div>
      {
        <PasswordConfirmation
          showModal={showModal}
          setShowModal={setShowModal}
          route={"/account"}
        />
      }
    </div>
  );
};

export default BookDetails;
