import React from "react";
import { Modal } from "react-bootstrap";
import "./style.css";
import { useNavigate } from "react-router-dom";
import { FaCheck } from "react-icons/fa";

const CongratulationModal = ({ showCongrateModal, setShowCongrateModal }) => {
  const navigate = useNavigate();
  const handleButtonClick = () => {
    navigate("/dashboard");
  };
  return (
    <div className="modal-main-div">
      <Modal show={showCongrateModal} className="modal-wrap">
        {/* <Modal.Header
          closeButton={true}
          className="modal-bg-color"
        ></Modal.Header> */}
        
        <Modal.Body className="modal-bg-color">
          <div className="congrate-main-container">
            <div className="check-icon-wrap">
              <FaCheck className="check-icon" />
            </div>
            <div>
              <p className="congrate-hd">Congratulations!</p>
            </div>
            <div>
              <p className="congrate-text">You have completed a book.</p>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="modal-bg-color">
          <button className="ok-btn" onClick={handleButtonClick}>
            Ok
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default CongratulationModal;
