import React, { useState, useEffect } from "react";
import "./styles.css";
import Container from "react-bootstrap/Container";
import "react-resizable/css/styles.css";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Offcanvas from "react-bootstrap/Offcanvas";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { IoNotificationsSharp } from "react-icons/io5";
import { BiSearchAlt } from "react-icons/bi";
import WordCheckModal from "../../Components/wordCheckModal/wordCheckModal";
import axios from "axios";
import { updateOrInsertwordLookup } from "../../Helpers/statsApiService";
import { toast } from "react-toastify";
import FictionGenieLogo from "../../assets/logo/logo-Icon-White.png";
import Logo from "../LogoComponent";
import { TfiMenu } from "react-icons/tfi";

const config = require("../../Helpers/config.json");

const DashboardNavbar = (props) => {
  const { bookData, selectedText, userLanguage } = props;
  const [open, setopen] = useState(false);
  const [profileImage, setProfileImage] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [dictionaryResultText, setDictionaryResultText] = useState("");
  const [wordCheckModal, setWordCheckModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigation = useNavigate();
  const location = useLocation();
  const admin = localStorage.getItem("enableUserCode")
    ? JSON.parse(localStorage.getItem("enableUserCode"))
    : "";

  useEffect(() => {
    const storedImage = localStorage.getItem("image");
    if (storedImage) {
      setProfileImage(storedImage);
    }
  }, [props?.profileUpdate]);

  useEffect(() => {
    if (props?.selectedText) {
      setSearchInput(props?.selectedText?.toString().trim());
    }
  }, [props?.selectedText]);

  const checkAdmin = () => {
    console.log("checling admin -->", admin);
    if (admin === true) {
      props?.setShowModal(true);
    } else {
      navigation("/account");
    }
  };

  const handleSearchButton = () => {
    if (!searchInput) {
      toast.error("Search Box is empty!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }
    setWordCheckModal(true);
    searchDetail();
  };

  const searchDetail = () => {
    setIsLoading(true);
    axios
      .get(`https://api.dictionaryapi.dev/api/v2/entries/en/${searchInput}`)
      .then(function (response) {
        let text = "";
        response?.data[0]?.meanings[0].definitions?.map((def) => {
          text = text + def?.definition + "\n ";
          return 0;
        });
        updateOrInsertwordLookup(
          localStorage.getItem("id"),
          localStorage.profileId,
          props?.bookData.id,
          searchInput,
          1,
          response?.data[0]?.meanings[0].definitions[0].definition
        );
        setDictionaryResultText(text);
        setIsLoading(false);
      })
      .catch(function (error) {
        console.log(error?.response?.data?.title);
        setDictionaryResultText(
          error?.response?.data?.title
            ? error?.response?.data?.title
            : "Definition Not Found"
        );
        setIsLoading(false);
      });
  };

  return (
    <div className="dashboard-navbar-section">
      <Navbar key={"lg"} expand={"lg"} className="nav-bars">
        <Container fluid className="navbar-wrapper-dashboard nav-bar-container">
          {location.pathname == "/dashboard" && (
            <div className="main-dashboard-container">
              <Logo />
              <div>
                <Navbar.Toggle
                  aria-controls={`offcanvasNavbar-expand-lg`}
                  className="color"
                >
                  <TfiMenu style={{ color: "white" }} />
                </Navbar.Toggle>
                <Navbar.Offcanvas
                  className="navbar-color"
                  id={`offcanvasNavbar-expand-lg`}
                  aria-labelledby={`offcanvasNavbarLabel-expand-lg`}
                  placement="end"
                >
                  <Offcanvas.Header closeButton>
                    <Navbar.Brand href="#">
                      <Logo />
                    </Navbar.Brand>
                    <Offcanvas.Title
                      id={`offcanvasNavbarLabel-expand-lg`}
                    ></Offcanvas.Title>
                  </Offcanvas.Header>
                  <Offcanvas.Body>
                    <Nav className="justify-content-end">
                      {/* <IoNotificationsSharp
                        className="notification-icon"
                        color="#FEBF0A"
                        size={35}
                        style={{ marginRight: "20px", marginTop: 12 }}
                      /> */}
                      <div
                        id="header-user-profile"
                        onClick={() => setopen(!open)}
                        className={open === true ? "active" : ""}
                      >
                        <div
                          id="header-user-profile-click dashboard-header-user-profile-click"
                          className="noselect"
                        >
                          <div className="jamal-profile-container">
                            {!profileImage ? (
                              <img
                                src={"https://placehold.co/400"}
                                alt="Account Image"
                              />
                            ) : (
                              <img
                                src={`${
                                  config["baseUrl2Image"]
                                }/resources/static/assets/uploads/profiles/${
                                  localStorage
                                    .getItem("image")
                                    .split("/")
                                    .pop()
                                    .split(".")[0]
                                }.png`}
                                alt="User"
                              />
                            )}
                            <p className="username">
                              {localStorage.getItem("name")}
                            </p>
                            <i
                              className="fas fa-angle-down"
                              style={{ color: "white" }}
                            />
                          </div>
                        </div>
                        <div
                          className="profile-menu-header"
                          id="header-user-profile-menu"
                        >
                          <ul>
                            <li>
                              <Link to="/dashboard" id="header-menu-data">
                                <span className="icon-Favorite-Window" />
                                Home
                              </Link>
                            </li>
                            <li onClick={() => checkAdmin()}>
                              <Link id="header-menu-data">
                                <span className="icon-User" />
                                Account
                              </Link>
                            </li>
                            <li>
                              <Link to="/profile" id="header-menu-data">
                                <span className="icon-Favorite-Window" />
                                Profile
                              </Link>
                            </li>
                            <li>
                              <Link to="/select-profile" id="header-menu-data">
                                <span className="icon-Favorite-Window" />
                                Switch Profile
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="jamal-profile-container-responsive">
                        {!profileImage ? (
                          <img
                            src={"https://placehold.co/400"}
                            alt="Account Image"
                          />
                        ) : (
                          <img
                            src={`${
                              config["baseUrl2Image"]
                            }/resources/static/assets/uploads/profiles/${
                              localStorage
                                .getItem("image")
                                .split("/")
                                .pop()
                                .split(".")[0]
                            }.png`}
                            alt="User"
                          />
                        )}
                        <p className="username">
                          {localStorage.getItem("name")}
                        </p>
                      </div>
                      <div className="responsive-navbar">
                        <li>
                          <Link
                            to="/dashboard"
                            className="responsive-navbar-link"
                          >
                            <span />
                            Home
                          </Link>
                        </li>
                        <li onClick={() => checkAdmin()}>
                          <Link className="responsive-navbar-link">
                            <span />
                            Account
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/profile"
                            className="responsive-navbar-link"
                          >
                            <span />
                            Profile
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/select-profile"
                            className="responsive-navbar-link"
                          >
                            <span />
                            Switch Profile
                          </Link>
                        </li>
                      </div>
                    </Nav>
                  </Offcanvas.Body>
                </Navbar.Offcanvas>
              </div>
            </div>
          )}  
          {location.pathname == "/book-detail" && (
            <div className="navbar-text-search-container">
              <div className="navbar-text-container">
                <Navbar.Brand
                  className=""
                  onClick={() => navigation("/dashboard")}
                >
                  <Logo />
                </Navbar.Brand>
              </div>
              <div className="search-bar-container">
                <div className="search-bar-wrap">
                  <div className="search-icon-wrap">
                    <BiSearchAlt
                      className="search-icon"
                      onClick={handleSearchButton}
                    />
                  </div>
                  <input
                    type="search"
                    className="search-bar"
                    placeholder="Word Check"
                    value={searchInput}
                    onChange={(e) => {
                      setSearchInput(e.target.value?.toString().trim());
                    }}
                  />
                </div>
              </div>
              <div className="menu-container">
                <Navbar.Toggle
                  aria-controls={`offcanvasNavbar-expand-lg`}
                  className="color"
                >
                  <TfiMenu style={{ color: "white" }} />
                </Navbar.Toggle>
                <Navbar.Offcanvas
                  className="navbar-color"
                  id={`offcanvasNavbar-expand-lg`}
                  aria-labelledby={`offcanvasNavbarLabel-expand-lg`}
                  placement="end"
                >
                  <Offcanvas.Header closeButton>
                    <Navbar.Brand href="#">
                      <Logo />
                    </Navbar.Brand>
                    <Offcanvas.Title
                      id={`offcanvasNavbarLabel-expand-lg`}
                    ></Offcanvas.Title>
                  </Offcanvas.Header>
                  <Offcanvas.Body>
                    <Nav className="justify-content-end">
                      <div
                        id="header-user-profile"
                        onClick={() => setopen(!open)}
                        className={open === true ? "active" : ""}
                      >
                        <div
                          id="header-user-profile-click dashboard-header-user-profile-click"
                          className="noselect"
                        >
                          <div className="jamal-profile-container">
                            {!profileImage ? (
                              <img
                                src={"https://placehold.co/400"}
                                alt="Account Image"
                              />
                            ) : (
                              <img
                                src={`${
                                  config["baseUrl2Image"]
                                }/resources/static/assets/uploads/profiles/${
                                  localStorage
                                    .getItem("image")
                                    .split("/")
                                    .pop()
                                    .split(".")[0]
                                }.png`}
                                alt="User"
                              />
                            )}
                            <p className="username">
                              {localStorage.getItem("name")}
                            </p>
                            <i
                              className="fas fa-angle-down"
                              style={{ color: "white" }}
                            />
                          </div>
                        </div>
                        <div
                          className="profile-menu-header"
                          id="header-user-profile-menu"
                        >
                          <ul>
                            <li>
                              <Link to="/dashboard" id="header-menu-data">
                                <span className="icon-Favorite-Window" />
                                Home
                              </Link>
                            </li>
                            <li onClick={() => checkAdmin()}>
                              <Link id="header-menu-data">
                                <span className="icon-User" />
                                Account
                              </Link>
                            </li>
                            <li>
                              <Link to="/profile" id="header-menu-data">
                                <span className="icon-Favorite-Window" />
                                Profile
                              </Link>
                            </li>
                            <li>
                              <Link to="/select-profile" id="header-menu-data">
                                <span className="icon-Favorite-Window" />
                                Switch Profile
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="jamal-profile-container-responsive">
                        {!profileImage ? (
                          <img
                            src={"https://placehold.co/400"}
                            alt="Account Image"
                          />
                        ) : (
                          <img
                            src={`${
                              config["baseUrl2Image"]
                            }/resources/static/assets/uploads/profiles/${
                              localStorage
                                .getItem("image")
                                .split("/")
                                .pop()
                                .split(".")[0]
                            }.png`}
                            alt="User"
                          />
                        )}
                        <p className="username">
                          {localStorage.getItem("name")}
                        </p>
                      </div>
                      <div className="responsive-navbar">
                        <li>
                          <Link
                            to="/dashboard"
                            className="responsive-navbar-link"
                          >
                            <span />
                            Home
                          </Link>
                        </li>
                        <li onClick={() => checkAdmin()}>
                          <Link className="responsive-navbar-link">
                            <span />
                            Account
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/profile"
                            className="responsive-navbar-link"
                          >
                            <span />
                            Profile
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/select-profile"
                            className="responsive-navbar-link"
                          >
                            <span />
                            Switch Profile
                          </Link>
                        </li>
                      </div>
                    </Nav>
                  </Offcanvas.Body>
                </Navbar.Offcanvas>
              </div>
            </div>
          )}
          {location.pathname == "/book-detail" && (
            <div className="navbar-text-search-container-responsive">
              {/* <div>
                <Navbar.Brand
                  className="navbar-text book-detail-responsive-text"
                  onClick={() => navigation("/dashboard")}
                >
                  FictionGenie
                </Navbar.Brand>
              </div> */}
              <div className="search-bar-container">
                <div className="search-bar-wrap">
                  <div className="search-icon-wrap">
                    <BiSearchAlt
                      className="search-icon"
                      onClick={handleSearchButton}
                    />
                  </div>
                  <input
                    type="search"
                    className="search-bar"
                    placeholder="Word Check"
                    value={searchInput}
                    onChange={(e) => {
                      setSearchInput(e.target.value?.toString().trim());
                    }}
                  />
                </div>
              </div>
            </div>
          )}
          <WordCheckModal
            userLanguage={userLanguage}
            dictionaryResultText={dictionaryResultText}
            wordCheckModal={wordCheckModal}
            isLoading={isLoading}
            searchInput={searchInput}
            setWordCheckModal={setWordCheckModal}
            bookData={bookData}
            selectedText={selectedText}
          />
        </Container>
      </Navbar>
    </div>
  );
};

export default DashboardNavbar;
