import React, { useState, useEffect } from 'react';
import booksRead from '../../assets/stats/booksRead.png'
import language from '../../assets/stats/language.png'
import translatecheck from '../../assets/stats/translatecheck.png'
import vocabCheck from '../../assets/stats/vocabCheck.png'
import './style.css';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import Book from '../../assets/book.png';



const config = require('../../Helpers/config.json');
const BookStats = (props) => {
  const { bookLanguageReadStats, bookReadStats, bookTranslationCheckedStats, wordsLookUpStats, continueReadData, allBookData } = props
  const [openModalForBooksReads, setOpenModalForBookReads] = useState(false);
  const [openModalForTranslation, setOpenModalForTranslation] = useState(false);
  const [openModalForVocabulary, setOpenModalForVocabulary] = useState(false);
  const [openModalForLanguagesRead, setOpenModalForLanguagesRead] = useState(false);
  const [translationData, setTranslationData] = useState('');
  const [fadeClass, setFadeClass] = useState('fade-in');

  const [animatedStats, setAnimatedStats] = useState({
    booksRead: 0,
    vocabularyCheck: 0,
    translationCheck: 0,
    languagesRead: 0,
    readingSpeed: 0,
    wordsRead: 0,
    booksListened: 0,
    genreRead: 0,
    questionsAsked: 0,
  });
  useEffect(() => {
    let matchingTransaltion;
    if (bookTranslationCheckedStats?.sentenceDetails?.length > 0 && allBookData?.length > 0) {
      matchingTransaltion = bookTranslationCheckedStats?.sentenceDetails?.map(obj1 => {
        const matchingBook = allBookData.find(obj2 => obj1.book_id === obj2.id);
        return { ...obj1, title: matchingBook ? matchingBook?.title : null }
      });
      setTranslationData(matchingTransaltion)
    }
  }, [bookTranslationCheckedStats, allBookData])
  useEffect(() => {
    const finalStats = {
      booksRead: bookReadStats || 0,
      vocabularyCheck: wordsLookUpStats?.wordDetails?.length || 0,
      translationCheck: bookTranslationCheckedStats?.sentenceDetails?.length || 0,
      languagesRead: bookLanguageReadStats?.languageDetails?.length || 0,
      readingSpeed: 43,
      wordsRead: 100,
      booksListened: 27,
      genreRead: 5,
      questionsAsked: 49,
    };

    const interval = setInterval(() => {
      setAnimatedStats((prevStats) => {
        const updatedStats = {};
        for (const stat in prevStats) {
          updatedStats[stat] = prevStats[stat] + 1;
          if (updatedStats[stat] >= finalStats[stat]) {
            updatedStats[stat] = finalStats[stat];
          }
        }
        return updatedStats;
      });
    }, 50);

    return () => clearInterval(interval);
  }, [props]);

  const handleValueCheck = (type) => {
    if (type === "translationCheck") {
      if (bookTranslationCheckedStats?.sentenceDetails?.length) {
        setOpenModalForTranslation(true)
      }
    }
    if (type === "vocabularyCheck") {
      if (wordsLookUpStats?.wordDetails?.length) {
        setOpenModalForVocabulary(true)
      }
    }
    if (type === "languageReadCheck") {
      if (bookLanguageReadStats?.languageDetails?.length) {
        setOpenModalForLanguagesRead(true)
      }
    }
  };
  const countValue = (count) => {
    return Number(count) + 1
  };

  useEffect(() => {
    console.log("trans" , translationData)
    console.log("bookTranslationCheckedStats", bookTranslationCheckedStats)
  }, [translationData, bookTranslationCheckedStats])


  return (
    <div>
      <h4 className='Stats-heading stats-of-profile'>Profile Stats</h4>
      Dive into delightful Profile Stats for a fun glimpse into your child's adventures! 📊 ✨
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        <div className="statBlock" onClick={() => setOpenModalForBookReads(true)}>
          <div className="icon" >
            <img src={booksRead} className="iconStyle" />
          </div>

          <div className="statName">
            <span className="subName">Books</span>
            <span>Read</span>
          </div>
          <p className="statValue">{animatedStats.booksRead}</p>
        </div>
        <Modal show={openModalForBooksReads}
          onClick={() => setOpenModalForBookReads(false)}>
          <Modal.Header closeButton={true} className='modal-container extra'>
            <h4 className='modal-hd'>Books Read</h4>
          </Modal.Header>
          <Modal.Body className='modal-container'>
          <p><b>Your child🌟read below books 📚</b></p>
            <div className='book-listing'>
              <table className='profile-books-read-wrap stats-table-container'>
                <tbody>
                  {continueReadData?.map((book, index) => (
                    <tr key={index} className='profile-book-img-text-wrap'>
                      <td className='td-style'>
                        <div className={`img-container profile-book-img-container ${fadeClass}`}>
                          <div className="dashboard-img-book-cover dashboard-img-book-cover-responsive profile-book-img">
                            <img src={`${config['baseUrl']}${book?.image}` || Book} alt={book.title} className='bookImage' />
                            <div className="book-spine"></div>
                          </div>
                        </div>
                      </td>
                      <td className='td-style'>
                        <div className='content-wrap me-5'>
                          <span className='title'>{book.title}</span>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <table className='profile-books-read-responsive'>
                <tbody>
                  {continueReadData?.map((book, index) => (
                    <div>
                      <tr key={index} className='profile-book-img-text-wrap'>
                        <td>
                          <div className={` img-container profile-book-img-container ${fadeClass}`}>
                            <div className="dashboard-img-book-cover dashboard-img-book-cover-responsive profile-book-img">
                              <img src={`${config['baseUrl']}${book?.image}` || Book} alt={book.title} className='bookImage' />
                              <div className="book-spine"></div>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className='content-wrap'>
                            <span className='title'>{book.title}</span>
                          </div>
                        </td>
                      </tr>
                    </div>
                  ))}
                </tbody>
              </table>
            </div>
          </Modal.Body>
          <Modal.Footer className='modal-container'>
            <button
              onClick={() => setOpenModalForBookReads(false)}
              className='btn btn-white-pro mt-3 mt-sm-4'>
              Close
            </button>
          </Modal.Footer>
        </Modal>
        <div className="statBlock" onClick={() => handleValueCheck("vocabularyCheck")}>
          <div className="icon">
            <img src={vocabCheck} className="iconStyle" />
          </div>
          <div className="statName">
            <span className="subName">Vocabulary</span>
            <span>Check</span>
          </div>
          <p className="statValue">{animatedStats.vocabularyCheck}</p>
        </div>
        <Modal show={openModalForVocabulary}
          onClick={() => setOpenModalForVocabulary(false)}>
          <Modal.Header closeButton={true} className='modal-container extra'>
            <h4 className='modal-hd'>Vocabulary Stats</h4>
          </Modal.Header>
          <Modal.Body className='modal-container'>
            {/* {JSON.stringify(bookTranslationCheckedStats?.sentenceDetails)} */}
            <p><b>Your child 🌟 searched below words in dictionary 📚</b></p>
            <table className='stats-table-container'>
              <thead>
                <tr>
                  {/* <th className='td-style'>Count</th> */}
                  <th className='td-style'>Words</th>
                </tr>
              </thead>
              <tbody>
                {
                  wordsLookUpStats?.wordDetails?.map((row, index) => {
                    return (<tr key={index}>
                      {/* <td className='td-style' >{countValue(row?.count)}</td> */}
                      <td className='td-style'>{row?.word}</td>
                    </tr>)
                  })
                }
              </tbody>
            </table>
          </Modal.Body>
          <Modal.Footer className='modal-container'>
            <button
              onClick={() => setOpenModalForVocabulary(false)}
              className='btn btn-white-pro mt-3 mt-sm-4'>
              Close
            </button>
          </Modal.Footer>
        </Modal>

        <div className="statBlock" onClick={() => handleValueCheck("translationCheck")}>
          <div className="icon">
            <img src={translatecheck} className="iconStyle" />
          </div>
          <div className="statName">
            <span className="subName">Translation</span>
            <span>Check</span>
          </div>
          <p className="statValue">{animatedStats?.translationCheck}</p>
        </div>
        <Modal size='md' show={openModalForTranslation}
          onClick={() => setOpenModalForTranslation(false)}>
          <Modal.Header closeButton={true} className='modal-container extra'>
            <h4 className='modal-hd'>Translation Stats</h4>
          </Modal.Header>
          <Modal.Body className='modal-container'>
            {/* {JSON.stringify(bookTranslationCheckedStats?.sentenceDetails)} */}
            <p><b>Your child 🌟 unlocked below words in different languages!🌎</b></p>
            <table className='stats-table-container'>
              <thead>
                <tr>
                  <th className='td-style'>Book</th>
                  <th className='td-style'>Word</th>
                  <th className='td-style'>Translated Language</th>
                </tr>
              </thead>
              <tbody>
                {
                translationData &&   translationData?.map((row, index) => {
                    return (<tr key={index}>
                      <td className='td-style' >{row?.title}</td>
                      <td className='td-style'>{row?.sentence}</td>
                      <td className='td-style'>{row?.language === "en" ? "English" : row?.language === "fr" ? "French" : row?.language === "de" ? "German" : row?.language === "ur" ? "Urdu" : row?.language === "zh-CN" ? "Chineese" : row?.language === "hi" ? "Hindi" : row?.language}</td>
                    </tr>)
                  })
                }
              </tbody>
            </table>


          </Modal.Body>
          <Modal.Footer className='modal-container'>
            <button
              onClick={() => setOpenModalForTranslation(false)}
              className='btn btn-white-pro mt-3 mt-sm-4'>
              Close
            </button>
          </Modal.Footer>
        </Modal>
        <div className="statBlock" onClick={() => handleValueCheck("languageReadCheck")}>
          <div className="icon">
            <img src={language} className="iconStyle" />
          </div>
          <div className="statName">
            <span className="subName">Languages</span>
            <span>Read</span>
          </div>
          <p className="statValue">{animatedStats.languagesRead}</p>
        </div>
        <Modal show={openModalForLanguagesRead}
          onClick={() => setOpenModalForLanguagesRead(false)}>
          <Modal.Header closeButton={true} className='modal-container extra'>
            <h4 className='modal-hd'>Books Read in different Languages</h4>
          </Modal.Header>
          <Modal.Body className='modal-container'>
            <p><b>Your child 🌟 unlocked below books in different languages!🌎</b></p>
            <table className='stats-table-container'>
              <thead>
                <tr>
                  {/* <th className='td-style'>Count</th> */}
                  <th className='td-style'>Language</th>

                </tr>
              </thead>
              <tbody>
                {
                  bookLanguageReadStats?.languageDetails?.map((row, index) => {
                    return (<tr key={index}>
                      {/* <td className='td-style' >{row?.count}</td> */}
                      <td className='td-style'>{row?.language}</td>
                    </tr>)
                  })
                }
              </tbody>
            </table>
          </Modal.Body>
          <Modal.Footer className='modal-container'>
            <button
              onClick={() => setOpenModalForLanguagesRead(false)}
              className='btn btn-white-pro mt-3 mt-sm-4'>
              Close
            </button>
          </Modal.Footer>
        </Modal>
      </div>

    </div>


  );
};

export default BookStats;
